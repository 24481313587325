// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apropos-js": () => import("./../../../src/pages/apropos.js" /* webpackChunkName: "component---src-pages-apropos-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galeries-js": () => import("./../../../src/pages/galeries.js" /* webpackChunkName: "component---src-pages-galeries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plaquette-js": () => import("./../../../src/pages/plaquette.js" /* webpackChunkName: "component---src-pages-plaquette-js" */),
  "component---src-templates-galerie-js": () => import("./../../../src/templates/galerie.js" /* webpackChunkName: "component---src-templates-galerie-js" */),
  "component---src-templates-information-js": () => import("./../../../src/templates/information.js" /* webpackChunkName: "component---src-templates-information-js" */)
}

